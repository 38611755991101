import React, { useEffect } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import Loadable from 'react-loadable';
import './App.scss';
import LoadingScreen from './components/LoadingScreen';

const loading = (props) => {
  if (props.pastDelay) {
    return LoadingScreen;
  } else {
    return null;
  }
}

const Catalog = Loadable({
  loader: () => import('./views/Catalog'),
  loading,
});

const Track = Loadable({
  loader: () => import('./views/Track'),
  loading,
});

const VerifyStock = Loadable({
  loader: () => import('./views/VerifyStock'),
  loading,
});

const App = () => {

  useEffect(()=>{
    let current = (new URL(document.location)).searchParams;
    current.get("merchantOrderId") ? location.href = "/track/"+current.get("merchantOrderId") : ''
  },[])

  return (
    <div className='App'>
      <Routes>
        <Route path='/' element={ <Navigate to="cashielab.html" /> }></Route>
        <Route path='/:outletId' element={<Catalog />} />
        <Route path='/:outletId/:type' element={<Catalog />} />
        <Route path='/:outletId/:type/:table' element={<Catalog />} />
        <Route path='/track/:pendingOrderId' element={<Track />} />
        <Route path='/verify-stock/:stockId' element={<VerifyStock />} />
      </Routes>
    </div>
  )
}

export default App;
